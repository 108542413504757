import { App, Button, Form, Input, Modal, ModalProps } from '@antd';
import { Function2 } from 'lodash';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { defaultFormItemConfig } from '@/constants/const';
import { updateUserPassword } from '@/services/user';
import { REGEX } from '@/constants/regex';
import { generateRandomPassword } from '../../const.var';
import { copyToClipboard } from '@/utils/copyToClipboard';
import Text from '@/components/Form/Field/Text';
import { Key } from 'react';
import { dict } from '@/hooks/useChangeLocale';

type ChangePasswordModalProps = ModalProps;
export const useChangePwdModal = (): [React.FC<ChangePasswordModalProps>, Function2<Key, Key, void>] => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [form] = Form.useForm();

  const ChangePasswordModalModal = useMemoizedFn((params: ChangePasswordModalProps) => {
    const { message } = App.useApp();
    const newPassword = Form.useWatch('newPassword', form);

    return (
      <Modal
        title={dict('CHANGE_PASSWORD')}
        open={isModalOpen}
        onCancel={hideModal}
        destroyOnClose
        {...params}
        onOk={async () => {
          try {
            const params = await form.validateFields();
            const result = await updateUserPassword({ ...params, loginId: undefined });
            if (result) {
              message.success(dict('CHANGE_PASSWORD_SUCCESS'));
              hideModal();
            }
          } catch (error) {}
        }}
        width={600}
      >
        <Form {...defaultFormItemConfig} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} form={form}>
          <Form.Item name="id" hidden>
            <Text />
          </Form.Item>
          <Form.Item name="loginId" label={dict('LOGIN_NAME')}>
            <Text />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label={dict('NEW_PASSWORD')}
            required
            rules={[
              {
                required: true,
                message: dict('PWD_RULE_TIP'),
                pattern: REGEX.PWD,
              },
            ]}
          >
            <Input.Password
              placeholder={dict('PLEASE_TYPE_NAME', { name: dict('NEW_PASSWORD') })}
              hidden
              autoComplete="new-password"
              addonBefore={
                <Button
                  size="small"
                  type="link"
                  onClick={() => {
                    form?.setFieldValue('newPassword', generateRandomPassword());
                  }}
                >
                  {dict('RANDOM')}
                </Button>
              }
              addonAfter={
                <Button
                  disabled={!newPassword}
                  size="small"
                  type="link"
                  onClick={async () => {
                    try {
                      await copyToClipboard(
                        `${dict('LOGIN_NAME')}: ${form.getFieldValue('loginId')}\n${dict('LOGIN_PWD')}: ${newPassword}`,
                      );
                      message.success(dict('COPY_TEXT_TO_CLIPBOARD'));
                    } catch (error) {
                      message.error(dict('COPY_TEXT_FAILURE'));
                    }
                  }}
                >
                  {/* 复制 */}
                  {dict('COPY')}
                </Button>
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  });

  return [
    ChangePasswordModalModal,
    (id, loginId) => {
      form?.setFieldsValue({ id, loginId, newPassword: '' });
      showModal();
    },
  ];
};
