import ChartCore from '@/components/ChartCore';
import { useRequest } from 'ahooks';
import { Spin } from '@antd';
import { getPieChartOptions } from './chart.confg';
import { getHomeUserGender } from '@/services/home';
import { isNil } from 'lodash';
import { dict } from '@/hooks/useChangeLocale';

const Chart = ({ bid }: { bid?: number }) => {
  const { data, loading } = useRequest(
    async () => {
      if (isNil(bid)) return undefined;

      return getHomeUserGender(bid);
    },
    {
      debounceWait: 200,
      refreshDeps: [bid],
    },
  );

  return (
    <Spin spinning={loading}>
      <ChartCore
        option={getPieChartOptions([
          { value: data?.male, name: dict('MALE') },
          { value: data?.female, name: dict('FEMALE') },
          { value: data?.unknow, name: dict('UNKNOWN') },
        ])}
        notMerge
        style={{ height: 300 }}
      />
    </Spin>
  );
};

export default Chart;
