import { dict } from '@/hooks/useChangeLocale';
import { UserDetail } from '@/services/user/type';
import { Flex, Modal, ModalProps, Transfer } from '@antd';
import { useBoolean, useCreation, useMemoizedFn } from 'ahooks';
import { intersection } from 'lodash';
import React, { Key, useState } from 'react';
import { Func1 } from 'redux';

type UserPickModalProps = {
  onUserSelect?: Func1<Key[], void>;
  users?: UserDetail[];
} & ModalProps;

const filterOption = (inputValue: string, option) => option.title.indexOf(inputValue) > -1;
export const useUserPickModal = (): [React.FC<UserPickModalProps>, Func1<Key[], void>] => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [userIds, setUserIds] = useState<Key[]>();

  const UserPickModal = useMemoizedFn(({ children, onUserSelect, users, ...params }) => {
    const dataSource = useCreation(
      () =>
        users?.map((v: UserDetail) => ({
          key: v.id,
          title: v.username,
          description: v.remark,
        })),
      [users],
    );

    const allIds = useCreation(() => dataSource?.map((v) => v.key), [dataSource]);

    return (
      <Modal
        title={dict('SELECT_NAME', { name: dict('USER') })} //选择用户
        open={isModalOpen}
        onCancel={hideModal}
        destroyOnClose
        onOk={() => {
          onUserSelect?.(userIds?.map(Number));
          hideModal();
        }}
        width={600}
        {...params}
      >
        <Flex justify="center">
          <Transfer
            dataSource={dataSource}
            showSearch
            filterOption={filterOption}
            listStyle={{
              width: 250,
              height: 300,
            }}
            targetKeys={intersection(userIds, allIds) as any}
            render={(item) => item.title}
            onChange={(nextTargetKeys: string[]) => setUserIds(nextTargetKeys)}
          />
        </Flex>
      </Modal>
    );
  });

  const handleRecord = useMemoizedFn((userIds: Key[]) => {
    setUserIds(userIds.map(Number));
    showModal();
  });

  return [UserPickModal, handleRecord];
};
