import CustomColumnModal from '@/components/CustomColumnModal';
import { DyColumnGroup } from '@/components/CustomColumnModal/type';
import AccountSelect from '@/components/Form/Field/select/AccountSelect';
import { OrgTreeSelect } from '@/components/Form/Field/select/OrgTreeSelect';
import Container, { Blank } from '@/components/PageCard';
import { TableQueryFilter } from '@/components/TableFilter';
import DateRangePicker, { rangePresets } from '@/components/TableFilter/RangePicker';
import { convertToTableColumns } from '@/constants/dynamic-column';
import {
  getWorkBenchExportDynamicColumn,
  saveDynamicColumnToLs,
} from '@/constants/dynamic-column/workbench/export/douyin';
import { useAppContext } from '@/contexts/AppContext';
import useTableRequest from '@/hooks/useTableRequest';
import { getReportDataPage, reportDataDataUpdateTime, reportDataDownload } from '@/services/report_data/douyin';
import { DateUtils } from '@/utils/dateUtil';
import { ProCard } from '@ant-design/pro-components';
import { Alert, Button, Form, Space, Table, Input } from '@antd';
import { useBoolean, useCreation, useRequest, useSafeState } from 'ahooks';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { Validator } from '@/utils/validator';
import { MEDIA_KEY_ENUM } from '@/constants/const';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { dict } from '@/hooks/useChangeLocale';
import { withPermission } from '@/components/Permission/withPermission';

const { isNilEmpty } = Validator;

const defaultParams = {
  orgIds: [],
  uniqueId: [],
  time: rangePresets[4].value,
};

const List = () => {
  const [baseParams, setBaseParams] = useSafeState<any>(cloneDeep(defaultParams));
  const { user } = useAppContext();

  const [tableColumnsConfig, setTableColumnsConfig] = useSafeState(getWorkBenchExportDynamicColumn(true));

  const [filterForm] = Form.useForm();
  const orgIds = Form.useWatch('orgIds', filterForm);
  const params = useCreation(() => {
    const { time, ...restParams } = baseParams;
    const [startTime, endTime] = DateUtils.convertRangeStr(time);
    return { ...restParams, startTime, endTime };
  }, [baseParams]);
  const [queryParams, setQueryParams] = useSafeState<any>({});
  const [customColumnVisible, { setTrue: showCustomColumn, setFalse: hideCustomColumn }] = useBoolean(false);

  const { run: download, loading: downloading } = useRequest(() => reportDataDownload(queryParams), { manual: true });

  const { tableProps } = useTableRequest(
    ({ pageIndex, pageSize, filters = {}, sorter }) => {
      const sortParams: any = {};

      if (sorter && sorter.field && sorter.order) {
        sortParams.sort = sorter.field;
        sortParams.direction = { ascend: 'asc', descend: 'desc' }[sorter.order];
      }

      const query = {
        ...params,
        bid: user?.bid,
        ...sortParams,
      };
      setQueryParams(query);
      return getReportDataPage({
        ...query,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params, user], debounceWait: 100 },
  );

  const { data: syncTime = '' } = useRequest(
    async () => {
      const time = await reportDataDataUpdateTime(user?.bid);
      if (!dayjs(time).isValid()) {
        return '';
      }
      return DateUtils.formatDateTime(time);
    },
    {
      refreshDeps: [user?.bid],
    },
  );

  const columns = convertToTableColumns(tableColumnsConfig, [], {
    width: 150,
    render: (val) => (isNilEmpty(val) ? '-' : val),
  }).map((item: any) => {
    if (
      [
        'livePeriodFeiyuTotalClueUv',
        'feiyuLiveClueCount',
        'lpJystNativeAdDyFollow',
        'lpJystNativeAdDyShare',
        'lpJystNativeAdDyComment',
        'lpJystNativeAdDyLike',
        'lpJystNativeAdDyHomeVisited',
        'lpJystNativeAdMessageAcion',
      ].includes(item.key)
    ) {
      return {
        ...item,
        width: 220,
      };
    }

    return item;
  });

  const { getBtnPermissionConfig } = usePermission();

  return (
    <Container>
      <Alert message={dict('DATA_UPDATE_TIME_TIP', { syncTime })} style={{ marginBottom: 10 }} />
      <ProCard>
        <TableQueryFilter
          initialValues={cloneDeep(defaultParams)}
          form={filterForm}
          onFinish={async (values) => {
            setBaseParams(values);
          }}
          defaultCollapsed={false}
        >
          <Form.Item name="orgIds" label={dict('AFFILIATION_ORG')}>
            <OrgTreeSelect
              placeholder={dict('PLEASE_SELECT_MULTIPLE_NAME', { name: dict('AFFILIATION_ORG') })}
              triggerChildrenChecked
              onChange={() => {
                filterForm.setFieldValue('accountIds', undefined);
              }}
            />
          </Form.Item>
          <Form.Item name="accountIds" label={dict('TIKTOK_NICKNAME')}>
            <AccountSelect
              placeholder={dict('PLEASE_SELECT_MULTIPLE_NAME', { name: dict('TIKTOK_NICKNAME') })}
              mode="multiple"
              maxTagCount="responsive"
              filterOrgIds={orgIds}
              filterMediaIds={MEDIA_KEY_ENUM.DOUYIN}
            />
          </Form.Item>
          <Form.Item name="distributorCode" label={dict('DEALER_CODE')}>
            <Input placeholder={dict('PLEASE_TYPE_NAME', { name: dict('DEALER_CODE') })} />
          </Form.Item>
          <Form.Item name="time" label={dict('TIME')}>
            <DateRangePicker style={{ width: '100%' }} allowClear={false} />
          </Form.Item>
        </TableQueryFilter>
      </ProCard>
      <Blank />

      <ProCard>
        <Space style={{ display: 'flex', marginBlockEnd: 10, justifyContent: 'space-between' }}>
          <Button
            loading={downloading}
            {...getBtnPermissionConfig(AUTH.DASHBOARD_STATISTICS.DOWNLOAD)}
            type="primary"
            onClick={download}
          >
            {dict('ACTION_DOWNLOAD_DATA')}
          </Button>
          <a onClick={showCustomColumn}>{dict('CUSTOM_COLUMNS')}</a>
        </Space>
        <Table {...tableProps} scroll={{ x: columns.length * 150 }} columns={columns} />
      </ProCard>

      <CustomColumnModal
        visible={customColumnVisible}
        tableColumnsConfig={tableColumnsConfig}
        defaultTableColumnsConfig={getWorkBenchExportDynamicColumn(true)}
        onCancel={hideCustomColumn}
        onConfigOK={(config: DyColumnGroup[]) => {
          saveDynamicColumnToLs(config);
          setTableColumnsConfig(config);
          hideCustomColumn();
        }}
      />
    </Container>
  );
};
export default withPermission(AUTH.DASHBOARD_STATISTICS.PAGE)(List);
