import { Blank, FormCard } from '@/components/PageCard';
import { defaultFormItemConfig } from '@/constants/const';
import { FormOpType } from '@/constants/enum';
import { REGEX } from '@/constants/regex';
import { App, Button, Flex, Form, Input, Radio } from '@antd';
import React from 'react';
import { generateRandomPassword, userStatusOptions } from '../../const.var';
import { checkUserName, createUser, updateUser } from '@/services/user';
import { useAppContext } from '@/contexts/AppContext';
import { get } from 'lodash';
import { useRequest } from 'ahooks';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { dict } from '@/hooks/useChangeLocale';

type FormContentProps = {
  opType: FormOpType;
  initialData?: any;
};

const back = () => history.back();

const FormContent: React.FC<FormContentProps> = ({ opType, initialData }) => {
  const { message } = App.useApp();
  const { user } = useAppContext();

  const [form] = Form.useForm();
  const username = Form.useWatch('username', form);
  const newPassword = Form.useWatch('passwordHash', form);
  const isCreateType = opType == FormOpType.CREATE;

  const { runAsync: submit, loading: submitting } = useRequest(
    async () => {
      try {
        const datas = await form.validateFields();
        const submitAction = initialData?.id ? updateUser : createUser;
        await submitAction(datas);
        message.success(dict('OPERATION_SUCCESS'));
        back();
      } catch (error: any) {
        if (error?.status?.message) {
          message.error(error?.status.message);
        }
      }
    },
    { manual: true },
  );

  return (
    <Form
      form={form}
      {...defaultFormItemConfig}
      validateTrigger={['onSubmit', 'onChange', 'onBlur']}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 8 }}
      initialValues={initialData}
    >
      <FormCard title={dict('BASIC_INFO')}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="bid" hidden initialValue={user?.bid}>
          <Input />
        </Form.Item>

        <Form.Item
          label={dict('USERNAME')}
          name="username"
          required
          rules={[
            { max: 50 },
            { pattern: /^[a-zA-Z0-9]+$/, message: dict('SUPPORT_EN_NUMBER_UNLINE_TEXT_TIP') },
            {
              validateTrigger: 'onBlur',
              async validator(_, name) {
                if (!name) return Promise.reject(dict('PLEASE_TYPE_NAME', { name: dict('USERNAME') }));
                try {
                  const exist = await checkUserName(name, initialData?.id);
                  if (exist) return Promise.reject(dict('NAME_EXISTED', { name: dict('USERNAME') }));
                } catch (error) {
                  return Promise.reject(get(error, 'status.message', dict('VALIDATION_EXCEIPTION')));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            placeholder={dict('PLEASE_TYPE_NAME', { name: dict('USERNAME') })}
            addonAfter={`@${user?.accountCode}`}
          />
        </Form.Item>

        <Form.Item
          label={dict('PASSWORD')}
          name="passwordHash"
          rules={[
            {
              required: isCreateType,
              message: dict('PWD_RULE_TIP'),
              pattern: REGEX.PWD,
            },
          ]}
        >
          <Input.Password
            hidden
            autoComplete="new-password"
            placeholder={dict('PLEASE_TYPE_NAME', { name: dict('NEW_PASSWORD') })}
            addonBefore={
              <Button
                size="small"
                type="link"
                onClick={() => {
                  form?.setFieldValue('passwordHash', generateRandomPassword());
                }}
              >
                {dict('RANDOM')}
              </Button>
            }
            addonAfter={
              <Button
                disabled={!newPassword || !username}
                size="small"
                type="link"
                onClick={async () => {
                  try {
                    await copyToClipboard(
                      `${dict('LOGIN_NAME')}: ${username}@${user?.accountCode},  ${dict('LOGIN_PWD')}: ${newPassword}`,
                    );
                    message.success(dict('COPY_TEXT_TO_CLIPBOARD'));
                  } catch (error) {
                    message.error(dict('COPY_TEXT_FAILURE'));
                  }
                }}
              >
                {dict('COPY')}
              </Button>
            }
          />
        </Form.Item>

        <Form.Item
          label={dict('STATUS')}
          name="enableStatus"
          required
          rules={[{ required: true }]}
          initialValue={userStatusOptions[0].value}
        >
          <Radio.Group options={userStatusOptions} />
        </Form.Item>
      </FormCard>
      <Blank />

      <FormCard title={dict('PLATFORM_CONFIG')}>
        <Form.Item label={dict('EMAIL')} name="email" rules={[{ type: 'email' }]}>
          <Input placeholder={dict('PLEASE_TYPE')} />
        </Form.Item>

        <Form.Item
          label={dict('PHONE_NUMBER')}
          name="mobile"
          rules={[{ message: dict('PLEASE_TYPE_NAME', { name: dict('PHONE_NUMBER') }), pattern: REGEX.PHONE_NUMBER }]}
        >
          <Input placeholder={dict('PLEASE_TYPE')} />
        </Form.Item>

        <Form.Item label={dict('REMARK')} name="remark" rules={[{ max: 255 }]}>
          <Input.TextArea placeholder={dict('PLEASE_TYPE')} />
        </Form.Item>
      </FormCard>
      <Blank />
      <FormCard>
        <Flex justify="center" gap={10}>
          <Button onClick={back}>{dict('ACTION_CANCEL')}</Button>
          <Button type="primary" loading={submitting} onClick={submit}>
            {dict('ACTION_SUBMIT')}
          </Button>
        </Flex>
      </FormCard>
    </Form>
  );
};

export default FormContent;
