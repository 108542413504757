import { MEDIA_ID_MAPPING, MEDIA_KEY_ENUM, ROUTE_PATH } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import { DeleteSolid, Reauthorize, Selected } from '@/icons';
import { themeConfig } from '@/layouts/const/themes';
import { launchLoginAuth } from '@/services/auth';
import { AccountAuthDetail, LoginAuthType } from '@/services/auth/type';
import { DateUtils } from '@/utils/dateUtil';
import { Format } from '@/utils/format';
import { App, Button, ButtonProps, Card, Checkbox, Form, Space } from '@antd';
import { useBoolean, useCreation, useRequest } from 'ahooks';
import { get } from 'lodash';
import { useState } from 'react';
import { LoginAuthStatuEnum } from '../../../const.var';
import style from '../style.less';
import ScanCodeModal from './ScanCodeModal';
import { launchShareLoginAuth } from '@/services/share';
import { AccountAvator } from '@/components/AccountAvator';
import { AuthorizeStatusEnum } from '@/constants/enum';
import { dict } from '@/hooks/useChangeLocale';

type LoginAuthProps = {
  id?: any; //平台授权ID  抖音环境需要传，快手不需要
  uuid?: any; //分享平台的UUID 快手必传
  data?: AccountAuthDetail; //账号授权详细
  refresh?: VoidFunction;
  loginAuthType?: LoginAuthType; //抖音有创建授权和登录授权
  media: MEDIA_KEY_ENUM; //平台
  updateShareAuthData?: (data) => void; //分享搜权需要更新方法
  updateAuthId?: (id) => void; //因为快手平台没有平台授权ID,扫码登录后会返回一个授权ID, 快手授权必传
};

//登录授权方法
const getLaunchLoginAuthAction = (isShareEnv: boolean) => {
  return isShareEnv ? launchShareLoginAuth : launchLoginAuth;
};

export default ({
  id,
  uuid,
  data,
  media,
  loginAuthType,
  refresh,
  updateShareAuthData,
  updateAuthId,
}: LoginAuthProps) => {
  const { modal, message } = App.useApp();
  const isShareAuthEnv = !!updateShareAuthData; //是否分享授权环境

  const [loginAuthStatus, loginAuthorizeTime] =
    loginAuthType == 'creator'
      ? ['creatorLoginAuthStatus', 'creatorLoginAuthorizeTime']
      : ['loginAuthStatus', 'loginAuthorizeTime'];
  const status = get(data, loginAuthStatus, AuthorizeStatusEnum.UNAUTHORIZED) as AuthorizeStatusEnum; //登录授权状态

  const [taskId, setTaskId] = useState(undefined);

  //抖音的authId可以区分账号，可认为是taskId,编辑时也或以区分账号 ， 其他平台没有登录授权，需要从启动浏览器里获取 ，爬虫登录授权时需要区分账户，和后端约定通过TASKID区分
  const authTaskId = id || taskId || '';

  //平台授权状态  快手平台不需要平台授权，则认为已经平台授权
  const isPlatformAuthed =
    MEDIA_KEY_ENUM.DOUYIN == media
      ? isShareAuthEnv
        ? !!id
        : get(data, 'authorizeStatus', AuthorizeStatusEnum.UNAUTHORIZED) == AuthorizeStatusEnum.ALREAD
      : true;

  //是否同意授权
  const [agreeLicense, { toggle: toggleAgreeLicense }] = useBoolean(status != AuthorizeStatusEnum.UNAUTHORIZED);

  const { getEnum } = useAppContext();

  //扫码状态
  const [qrStatus, setQrStatus] = useState<string>();

  //登录授权弹框
  const [isModalOpen, { setTrue: showQrCodeModal, setFalse: hideQrCodeModal }] = useBoolean(false);

  //登录状态文本显示
  const loginStatusTextMap = useCreation(() => {
    let map: any = {
      authText: dict('OPEN_QR_CODE_POP_UP_TIP'),
      authBtnText: dict('AUTHORIZATION').toLowerCase(),
      icon: null,
    };
    if (status == AuthorizeStatusEnum.ALREAD) {
      map = {
        authText: dict('ACCOUNT_STATUS_SUCCESS'),
        authBtnText: dict('REAUTHORIZATION'),
        icon: (
          <Selected
            className={style['authed']}
            style={{
              fontSize: 14,
              color: '#14ae5c',
            }}
          />
        ),
      };
    }
    if (status == AuthorizeStatusEnum.EXCEPTION || status == AuthorizeStatusEnum.EXPIRE) {
      map = {
        authText: dict('ACCOUNT_STATUS_FAILURE'),
        authBtnText: dict('REAUTHORIZATION'),
        icon: <DeleteSolid className={style['unauthed']} />,
      };
    }

    if (
      [LoginAuthStatuEnum.accountError, LoginAuthStatuEnum.accountBindError].includes(qrStatus as LoginAuthStatuEnum)
    ) {
      map.authText = Format.toArrLabel(getEnum('LeadsAccountAuthStatusEnum'), qrStatus, 'name', 'displayName');
    }
    return {
      ...map,
    };
  }, [status, qrStatus]);

  const { run: startClient, loading: clientStarting } = useRequest(
    async () => {
      const launchLoginAuthAction = getLaunchLoginAuthAction(isShareAuthEnv);
      try {
        const result = await launchLoginAuthAction(authTaskId, MEDIA_ID_MAPPING[media], loginAuthType);
        showQrCodeModal();
        if (get(result, 'data.accountId')) {
          setTaskId(result.data.accountId);
        }
      } catch (error) {
        message.error(get(error, 'status.message'));
      }
    },
    { refreshDeps: [isShareAuthEnv, taskId, id, media, loginAuthType], manual: true },
  );

  const launchBrowserClient = () => {
    if (status == AuthorizeStatusEnum.ALREAD) {
      modal.confirm({
        title: dict('CONFIRM_REAUTHORIZATION'),
        content: dict('AFTER_REAUTHORIZATION_TIP'),
        onOk: startClient,
      });
    } else {
      startClient();
    }
  };

  const authBtnConfig: ButtonProps = {
    type: 'link',
    size: 'small',
    loading: clientStarting,
    disabled: !isPlatformAuthed || !agreeLicense,
    title: isPlatformAuthed
      ? agreeLicense
        ? undefined
        : dict('PLEASE_AGREE_AUTHORIZE')
      : dict('PLEASE_PLATFORM_AUTHORIZE'),
  };

  return (
    <>
      <Form.Item label={loginAuthType ? dict('CREATOR_AUTHORIZATION') : dict('LOGIN_AUTHORIZATION')} required>
        <Card
          style={{
            maxWidth: 430,
            borderColor: '#f0f0f0',
          }}
          bodyStyle={{ padding: 2 }}
        >
          {status != AuthorizeStatusEnum.UNAUTHORIZED ? (
            <div>
              <div
                style={{
                  padding: '8px 12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {media == MEDIA_KEY_ENUM.DOUYIN ? (
                    <Space>
                      {loginStatusTextMap.icon}
                      {loginStatusTextMap.authText}
                    </Space>
                  ) : (
                    <AccountAvator avaterUrl={get(data, 'avatar')} name={data?.name || ''} />
                  )}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Reauthorize style={{ fontSize: 16, color: themeConfig.colorPrimary }} />
                  <Button {...authBtnConfig} style={{ marginLeft: -5 }} onClick={launchBrowserClient}>
                    {loginStatusTextMap.authBtnText}
                  </Button>
                </div>
              </div>
              <div style={{ color: 'rgba(0,0,0,0.4)', paddingRight: 5, textAlign: 'right' }}>
                {dict('AUTHORIZATION_TIME')}：{DateUtils.formatDateTime(get(data, loginAuthorizeTime))}
              </div>
            </div>
          ) : (
            <div style={{ padding: '4px 8px' }}>
              {loginStatusTextMap.authText}

              <Button {...authBtnConfig} style={{ marginLeft: -5 }} onClick={launchBrowserClient}>
                {loginStatusTextMap.authBtnText}
              </Button>
            </div>
          )}
        </Card>
        <div style={{ marginTop: 10 }}>
          <Checkbox defaultChecked={agreeLicense} onChange={toggleAgreeLicense}>
            {dict('CHECK_INDICATE_AGREEMENT')}
          </Checkbox>
          <a onClick={() => window.open(`${BASE_PATH}/${ROUTE_PATH.LICENSE}/${ROUTE_PATH.LOGIN_AUTH_AGREEMENT}`)}>
            {dict('APPLICAITON_LICENSE_AGREEMENT')}
          </a>
        </div>
      </Form.Item>
      <ScanCodeModal
        isModalOpen={isModalOpen}
        onHandleCancel={hideQrCodeModal}
        setQrStatus={setQrStatus}
        isShareAuth={isShareAuthEnv}
        restartLaunchLoginAuthAction={(taskId) => {
          const lunchLoiginAuthAction = getLaunchLoginAuthAction(isShareAuthEnv);
          return lunchLoiginAuthAction(taskId, MEDIA_ID_MAPPING[media]);
        }}
        loginAuthType={loginAuthType}
        media={media}
        onHandleSuccess={(result) => {
          hideQrCodeModal();
          refresh?.();

          if (media != MEDIA_KEY_ENUM.DOUYIN && result.authId) {
            updateAuthId?.(result.authId);
          }
          //更新登录授权状态
          if (isShareAuthEnv) {
            let data: any = {
              [loginAuthorizeTime]: DateUtils.formatDateTime(),
              [loginAuthStatus]: result.status ? AuthorizeStatusEnum.ALREAD : AuthorizeStatusEnum.EXCEPTION,
            };
            if (media != MEDIA_KEY_ENUM.DOUYIN) {
              data = { ...data, name: result?.name, hashId: result?.hashId };
            }
            updateShareAuthData?.(data);
          }
        }}
        id={authTaskId}
        uuid={uuid}
      />
    </>
  );
};
