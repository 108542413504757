import { AccountAvator } from '@/components/AccountAvator';
import { MEDIA_ENUM, MEDIA_KEY_ENUM, ROUTE_PATH } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import { ArrowDown, ArrowUp, Reauthorize, Selected, UnSelected } from '@/icons';
import { themeConfig } from '@/layouts/const/themes';
import { authPlatform } from '@/services/auth';
import { AccountAuthDetail } from '@/services/auth/type';
import { getPlatformUrl } from '@/services/platform';
import { getScopList } from '@/services/scope';
import { authSharePlatform, getSharePlatformUrl, getShareScopeList } from '@/services/share';
import { Convert } from '@/utils/convert';
import { DateUtils } from '@/utils/dateUtil';
import { Validator } from '@/utils/validator';
import { Alert, App, Button, Card, Checkbox, Form, List, Space } from '@antd';
import { useBoolean, useCreation, useEventListener, useMemoizedFn, useRequest } from 'ahooks';
import { get } from 'lodash';
import { AccountTypeEnum } from '../../../../const.var';
import style from '../../style.less';
import { AuthorizeStatusEnum } from '@/constants/enum';
import { dict } from '@/hooks/useChangeLocale';

type PlatformAuthProps = {
  id: any; //授权ID   //系统授权时使用
  setAuthId?: (id) => void; //刷新授权状态
  data?: AccountAuthDetail;
  refresh?: VoidFunction; //刷新
  accountType: AccountTypeEnum;

  //分享授权时使用
  uuid?: string; //分享时的临时ID
  updateShareAuthData?: (data) => void; //更新分享授权数据
};
export default ({ id, uuid, setAuthId, data, refresh, accountType, updateShareAuthData }: PlatformAuthProps) => {
  const isShareAuth = !!updateShareAuthData; //是否分享授权
  const { message, modal } = App.useApp();
  const { user } = useAppContext();
  const [authorizing, { setTrue: setAuthorizing, setFalse: cancelAuthorizing }] = useBoolean(false);
  const [allScopVisible, { toggle: toggleScopList }] = useBoolean(false);
  const status = Validator.isNilEmpty(data) ? AuthorizeStatusEnum.UNAUTHORIZED : data?.authorizeStatus;
  const [agreeLicense, { toggle: toggleAgreeLicense }] = useBoolean(status != AuthorizeStatusEnum.UNAUTHORIZED);

  //授权地址
  const { data: platformUrl } = useRequest(() => {
    const getPlatformUrlAction = isShareAuth ? getSharePlatformUrl : getPlatformUrl;
    return getPlatformUrlAction(MEDIA_ENUM.DOUYIN);
  });

  //权限列表
  const { data: scopList, refresh: refreshScopeList } = useRequest(
    async () => {
      const getScopeListAction = isShareAuth ? getShareScopeList : getScopList;
      return id ? getScopeListAction(id) : [];
    },
    {
      refreshDeps: [id, isShareAuth],
    },
  );

  const systemAuth = useMemoizedFn((authUrl) => {
    const { code } = Convert.queryStringToObj(authUrl);
    authPlatform({
      id,
      authCode: code,
      bid: user?.bid,
      tenantId: user?.tenantId,
      mediaId: MEDIA_KEY_ENUM.DOUYIN,
      accountType,
    })
      .then((resp) => {
        if (resp.authStatus) {
          message.success(dict('ACCOUNT_STATUS_FAILURE'));
          setAuthId?.(resp.id);
          if (id) {
            refresh?.();
            refreshScopeList();
          }
        } else {
          modal.error({
            title: dict('ACCOUNT_STATUS_EXCEPTION'),
            content: get(resp, 'status.message'),
          });
        }
      })
      .catch((error) => {
        message.error(get(error, 'status.message', dict('ACCOUNT_STATUS_EXCEPTION')));
      })
      .finally(cancelAuthorizing);
  });

  const shareAuth = useMemoizedFn((authUrl) => {
    const { code } = Convert.queryStringToObj(authUrl);
    authSharePlatform(code, uuid, accountType)
      .then((resp) => {
        if (resp.authStatus) {
          message?.success(dict('ACCOUNT_STATUS_SUCCESS'));
          updateShareAuthData?.({ authorizeTime: DateUtils.formatDateTime(), ...resp });
          refreshScopeList();
        } else {
          modal.error({
            title: dict('ACCOUNT_STATUS_EXCEPTION'),
            content: get(resp, 'status.message'),
          });
        }
      })
      .catch((error) => {
        message?.error(get(error, 'status.message', dict('ACCOUNT_STATUS_EXCEPTION')));
      })
      .finally(cancelAuthorizing);
  });

  useEventListener(
    'message',
    (e) => {
      const authUrl = e?.data;
      if (!authorizing || typeof authUrl !== 'string' || authUrl === '[tea-sdk]ready') return;
      isShareAuth ? shareAuth(authUrl) : systemAuth(authUrl);
    },
    { target: window },
  );

  //授权状态
  const authStatusTextMap = useCreation(() => {
    let map = {
      authText: dict('GOTO_PLATFORM_AUTHORIZATION_PAGE_TIP'),
      authBtnText: dict('AUTHORIZATION').toLowerCase(),
    };
    let authMsg = '';
    if (status != AuthorizeStatusEnum.UNAUTHORIZED) {
      map = {
        authText: dict('CLICK_HERE'),
        authBtnText: dict('REAUTHORIZATION'),
      };
      if (status == AuthorizeStatusEnum.EXCEPTION) authMsg = dict('ACCOUNT_AUTHORIZATION_CANCELED_TIP');
      if (status == AuthorizeStatusEnum.EXPIRE) authMsg = dict('ACCOUNT_AUTHIRIZATION_EXPIRED_TIP');
    }

    return {
      ...map,
      authMsg,
    };
  }, [status]);

  const authBtn = (
    <Button
      type="link"
      loading={authorizing}
      disabled={!agreeLicense}
      title={agreeLicense ? undefined : dict('PLEASE_AGREE_AUTHORIZE')}
      style={{ padding: 0 }}
      onClick={() => {
        window.open(platformUrl);
        setAuthorizing();
      }}
    >
      {authStatusTextMap.authBtnText}
    </Button>
  );

  return (
    <Form.Item label={dict('PLATFORM_AUTHORIZATION')} required>
      <Card
        style={{
          maxWidth: 430,
          borderColor: authStatusTextMap.authMsg.length > 0 ? '#F53F3F' : '#f0f0f0',
        }}
        bodyStyle={{ padding: 2 }}
      >
        {status != AuthorizeStatusEnum.UNAUTHORIZED ? (
          <div style={{ padding: 5, display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <AccountAvator avaterUrl={get(data, 'avatar')} name={data?.name || ''} />
            </div>
            <div>
              <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                <Reauthorize style={{ fontSize: 16, color: themeConfig.colorPrimary }} />
                {authBtn}
              </div>
              <div style={{ color: 'rgba(0,0,0,0.4)' }}>
                {dict('AUTHORIZATION_TIME')}：{DateUtils.formatDateTime(data?.authorizeTime)}
              </div>
            </div>
          </div>
        ) : (
          <div style={{ padding: '0px 8px' }}>
            {authStatusTextMap.authText}&nbsp;{authBtn}
          </div>
        )}
      </Card>

      <div style={{ marginTop: 10 }}>
        <Checkbox defaultChecked={agreeLicense} onChange={toggleAgreeLicense}>
          {dict('CHECK_INDICATE_AGREEMENT')}
        </Checkbox>
        <a onClick={() => window.open(`${BASE_PATH}/${ROUTE_PATH.LICENSE}/${ROUTE_PATH.LOGIN_AUTH_AGREEMENT}`)}>
          {dict('APPLICAITON_LICENSE_AGREEMENT')}
        </a>
      </div>
      {status != AuthorizeStatusEnum.UNAUTHORIZED ? (
        <div>
          {authStatusTextMap.authMsg ? (
            <Alert type="error" message={authStatusTextMap.authMsg} className={style['error-msg']} closable={false} />
          ) : null}

          <div style={{ margin: '10px 0  10px 0' }}>{dict('OBTAINED_FOLLOWING_PERMISSION')}：</div>
          <List
            style={{ marginLeft: 20, maxWidth: 430 }}
            itemLayout="horizontal"
            dataSource={allScopVisible ? scopList : scopList?.filter((v) => v.checked)}
            split={false}
            renderItem={(item) => (
              <List.Item key={item.desc}>
                <Space style={{ margin: '-5px 0', color: 'rgba(0,0,0,0.6)' }}>
                  {item.checked ? (
                    <Selected className={style['authed']} />
                  ) : (
                    <UnSelected className={style['unauthed']} />
                  )}
                  <div style={{ marginLeft: -5 }}>{item.desc}</div>
                </Space>
              </List.Item>
            )}
          />
          <div style={{ margin: '10px 0  10px 0', cursor: 'pointer' }} onClick={toggleScopList}>
            {allScopVisible ? (
              <>
                {dict('RETRACT_UNOBTAINED_FOLLOWING_PERMISSION')} <ArrowUp />
              </>
            ) : (
              <>
                {dict('EXPEND_UNOBTAINED_FOLLOWING_PERMISSION')} <ArrowDown />
              </>
            )}
          </div>
        </div>
      ) : null}
    </Form.Item>
  );
};
