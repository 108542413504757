import EnumRadio from '@/components/Form/Field/EnumRadio';
import Text from '@/components/Form/Field/Text';
import { OrgTreeSelect } from '@/components/Form/Field/select/OrgTreeSelect';
import { Blank, FormCard } from '@/components/PageCard';
import { MEDIA_KEY_ENUM, ROUTE_PATH, defalutFormInputStyle, defaultFormItemConfig } from '@/constants/const';
import { REGEX } from '@/constants/regex';
import { useAppContext } from '@/contexts/AppContext';
import { getAccount, upsertAccount } from '@/services/account';
import { getAuthDetail } from '@/services/auth';
import { Alert, App, Button, Form, Input, Space } from '@antd';
import { useAsyncEffect, useBoolean, useRequest } from 'ahooks';
import { get } from 'lodash';
import React, { Key, useState } from 'react';
import { AccountTypeEnum } from '../../const.var';
import PoiSetInput from '../../../../../components/Form/Field/poi/RegionPoiModal';
import LoginAuth from './platform/LoginAuth';
import PlatfromAuth from './platform/douyin/PlatfromAuth';
import PlatformSelect from '@/components/Form/Field/select/PlatformSelect';
import { dict } from '@/hooks/useChangeLocale';

type AuthFormType = {
  id?: Key; //授权ID
  back?: VoidFunction;
};
const AuthForm: React.FC<AuthFormType> = ({ id, back }) => {
  const { message } = App.useApp();
  const { user, platforms } = useAppContext();
  const [form] = Form.useForm();
  const accountType = Form.useWatch('accountType', form);
  const mediaId = Form.useWatch('mediaId', form);

  const [authId, setAuthId] = useState(id ? Number(id) : undefined);
  const [submitting, { setTrue: setSubmitting, setFalse: hideSubmitting }] = useBoolean(false);

  const showAccountInfo = !!authId; //完成平台授权 ，可以显示账号信息
  const editDisabled = !!authId; //已授权后平台和账号类型是否可编辑

  //加载登录授权详情
  const {
    data: authDetail,
    loading,
    refresh,
  } = useRequest(
    async () => {
      if (!authId) return undefined;
      return getAuthDetail(authId);
    },
    { refreshDeps: [authId] },
  );

  useAsyncEffect(async () => {
    if (!authId || !location.href.includes(ROUTE_PATH.EDIT)) return;
    const account = await getAccount(authId);
    form.setFieldsValue({ ...account, poi: get(account, 'poiVO') });
  }, [authId]);

  const submit = async () => {
    try {
      const values = await form.validateFields();
      setSubmitting();
      await upsertAccount({ ...values, authId, name: authDetail?.name, bid: user?.bid });
      back?.();
    } catch (error: any) {
      if (error?.errorFields) {
        form.scrollToField(get(error, 'errorFields[0].name[0]', ''));
      } else {
        message.error(get(error, 'status.message', dict('OPERATION_FAILURE')));
      }
    } finally {
      hideSubmitting();
    }
  };

  return (
    <>
      <Alert style={{ marginBottom: 10 }} message={dict('ACCOUNT_AUTHORIZED_TIP')} />
      <FormCard title={dict('BASIC_INFO')} loading={loading}>
        <Form {...defaultFormItemConfig} labelCol={{ span: 6 }} wrapperCol={{ span: 6 }} form={form}>
          <Form.Item label={dict('PLATFORM')} name="mediaId" required initialValue={platforms[0].id}>
            <PlatformSelect disabled={editDisabled} />
          </Form.Item>

          {mediaId == MEDIA_KEY_ENUM.DOUYIN && ( //抖音授权
            <>
              <Form.Item
                label={dict('ACCOUNT_TYPE')}
                name="accountType"
                required
                initialValue={AccountTypeEnum.ENTERPRISE}
              >
                <EnumRadio
                  type="AccountTypeEnum"
                  valueKey="name"
                  disabled={editDisabled}
                  excludeValues={[AccountTypeEnum.SERVICE_PROVIDER]}
                />
              </Form.Item>
              <PlatfromAuth
                id={authId}
                data={authDetail}
                accountType={accountType}
                setAuthId={setAuthId}
                refresh={refresh}
              />
              <LoginAuth loginAuthType="creator" media={mediaId} id={authId} data={authDetail} refresh={refresh} />

              {accountType == AccountTypeEnum.ENTERPRISE && (
                <LoginAuth media={mediaId} id={authId} data={authDetail} refresh={refresh} />
              )}
              <Form.Item label={dict('AUTHORIZED_ACCOUNT')}>
                <Text value={authDetail?.name ? authDetail?.name : dict('UNAUTHORIZATION_TIP')} />
              </Form.Item>
            </>
          )}

          {[MEDIA_KEY_ENUM.KUAISHOU, MEDIA_KEY_ENUM.WEIXIN, MEDIA_KEY_ENUM.XIAOHONGSHU].includes(mediaId) && ( //非抖音平台授权
            <>
              <LoginAuth media={mediaId} id={authId} data={authDetail} updateAuthId={setAuthId} refresh={refresh} />
              <Form.Item label={dict('AUTHORIZED_ACCOUNT')}>
                <Text value={authDetail?.name ? authDetail?.name : dict('UNLOGIIN_AUTHORIZATION')} />
              </Form.Item>
            </>
          )}
        </Form>

        {showAccountInfo && (
          <Form {...defaultFormItemConfig} labelCol={{ span: 6 }} wrapperCol={{ span: 6 }} form={form}>
            <Form.Item name="orgId" label={dict('AFFILIATION_ORG')} tooltip={dict('AUTHORIZATION_ORG_UNSELECTED_TIP')}>
              <OrgTreeSelect style={defalutFormInputStyle} />
            </Form.Item>

            <Form.Item
              label={dict('OPERATOR_PHONE_NUM')}
              name="operatorPhoneNum"
              rules={[
                {
                  pattern: REGEX.PHONE_NUMBER,
                  message: dict('PHONENUMBER_PLACEHOLDER'),
                },
              ]}
            >
              <Input placeholder={dict('PHONENUMBER_PLACEHOLDER')} style={defalutFormInputStyle} />
            </Form.Item>

            <Form.Item label={dict('POI_POSITION')} name="poi">
              <PoiSetInput style={defalutFormInputStyle} />
            </Form.Item>

            <Form.Item label={dict('REMARK')} name="remark" rules={[{ max: 255 }]}>
              <Input.TextArea maxLength={255} showCount style={defalutFormInputStyle} />
            </Form.Item>
          </Form>
        )}
      </FormCard>
      {showAccountInfo && (
        <>
          <Blank />
          <FormCard>
            <Space style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={back}>{dict('ACTION_CANCEL')}</Button>
              <Button type="primary" loading={submitting} onClick={submit}>
                {dict('ACTION_SUBMIT')}
              </Button>
            </Space>
          </FormCard>
        </>
      )}
    </>
  );
};

export default AuthForm;
