import Text from '@/components/Form/Field/Text';
import { Blank, FormCard } from '@/components/PageCard';
import { MEDIA_KEY_ENUM, defaultFormItemConfig } from '@/constants/const';
import { REGEX } from '@/constants/regex';
import { createShareAccount } from '@/services/share';
import { App, Button, Form, Input, Radio, Space } from '@antd';
import { useAsyncEffect, useMemoizedFn, useRequest } from 'ahooks';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { AccountTypeEnum } from '../../const.var';
import LoginAuth from './platform/LoginAuth';
import PlatfromAuth from './platform/douyin/PlatfromAuth';
import PlatformSelect from '@/components/Form/Field/select/PlatformSelect';
import { dict } from '@/hooks/useChangeLocale';
import { useAppContext } from '@/contexts/AppContext';
import { getPlatformList } from '@/services/platform';

type AuthFormType = {
  uuid: string; //分享的uuid
  authDetail: any;
  updateShareAuthData: (data) => void;
};
const formItemConfig = { ...defaultFormItemConfig, labelCol: { span: 8 }, wrapperCol: { span: 8 } };
const AuthForm: React.FC<AuthFormType> = ({ uuid, authDetail, updateShareAuthData }) => {
  const { message } = App.useApp();
  const { setData } = useAppContext();
  const platformAuthed = !!authDetail?.hashId;
  const [form] = Form.useForm();
  const accountType = Form.useWatch('accountType', form);
  const mediaId = Form.useWatch('mediaId', form);

  const editDisabled = !!authDetail?.hashId && accountType; //已授权后平台和账号类型是否可编辑

  const { runAsync: submit, loading } = useRequest(
    async () => {
      try {
        const datas = await form.validateFields();
        const submitDatas = { ...datas, hashId: authDetail?.hashId };
        createShareAccount(submitDatas);
        updateShareAuthData({ ...submitDatas, ...authDetail });
        message.success(dict('OPERATION_SUCCESS'));
      } catch (error) {
        message.error(get(error, 'status.message', dict('REQUIED_ITEM_CHECKED_TIP')));
      }
    },
    { refreshDeps: [form, authDetail?.hashId], manual: true },
  );

  const updateShareData = useMemoizedFn((result) => {
    updateShareAuthData({ ...result, mediaId, accountType });
  });

  useEffect(() => {
    if (platformAuthed) form.setFieldsValue(authDetail);
  }, [platformAuthed, form]);

  useAsyncEffect(async () => {
    const platforms = await getPlatformList();
    setData({ platforms });
    form.setFieldValue('mediaId', platforms[0].id);
  }, []);

  return (
    <>
      <FormCard title={dict('BASIC_INFO')}>
        <Form {...formItemConfig} form={form}>
          <Form.Item label={dict('PLATFORM')} name="mediaId" required>
            <PlatformSelect disabled={editDisabled} />
          </Form.Item>

          {mediaId == MEDIA_KEY_ENUM.DOUYIN && ( //抖音授权
            <>
              <Form.Item
                label={dict('ACCOUNT_TYPE')}
                name="accountType"
                required
                initialValue={AccountTypeEnum.ENTERPRISE}
              >
                <Radio.Group
                  options={[
                    { label: dict('ENTERPRISE_ACCOUNT'), value: AccountTypeEnum.ENTERPRISE },
                    { label: dict('PERSONAL_ACCOUNT'), value: AccountTypeEnum.PRESIONAL },
                  ]}
                  disabled={platformAuthed}
                />
              </Form.Item>

              <PlatfromAuth
                id={authDetail?.hashId}
                uuid={uuid}
                data={authDetail}
                accountType={accountType}
                updateShareAuthData={updateShareData}
              />
              <LoginAuth
                loginAuthType="creator"
                media={MEDIA_KEY_ENUM.DOUYIN}
                id={authDetail?.hashId}
                data={authDetail}
                uuid={uuid}
                updateShareAuthData={updateShareData}
              />

              {accountType == AccountTypeEnum.ENTERPRISE && (
                <LoginAuth
                  media={MEDIA_KEY_ENUM.DOUYIN}
                  id={authDetail?.hashId}
                  data={authDetail}
                  uuid={uuid}
                  updateShareAuthData={updateShareData}
                />
              )}
              <Form.Item label={dict('AUTHORIZED_ACCOUNT')}>
                <Text value={authDetail?.name ? authDetail?.name : dict('UNAUTHORIZATION_TIP')} />
              </Form.Item>
            </>
          )}

          {[MEDIA_KEY_ENUM.KUAISHOU, MEDIA_KEY_ENUM.WEIXIN, MEDIA_KEY_ENUM.XIAOHONGSHU].includes(mediaId) && ( //快手授权
            <>
              <LoginAuth
                media={mediaId}
                id={authDetail?.hashId}
                data={authDetail}
                uuid={uuid}
                updateShareAuthData={updateShareData}
              />
              <Form.Item label={dict('DELER_CODE')}>
                <Text value={authDetail?.name ? authDetail?.name : dict('UNLOGIIN_AUTHORIZATION')} />
              </Form.Item>
            </>
          )}

          <Form.Item label={dict('DELER_CODE')} name="code" required rules={[{ required: true }]}>
            <Input placeholder={dict('DELER_CODE_REQUIRED_TIP')} />
          </Form.Item>

          <Form.Item
            label={dict('PHONE_NUMBER')}
            name="phoneNumber"
            rules={[{ pattern: REGEX.PHONE_NUMBER, message: dict('PHONENUMBER_PLACEHOLDER'), validateTrigger: 'blur' }]}
          >
            <Input placeholder={dict('PHONENUMBER_PLACEHOLDER')} />
          </Form.Item>
        </Form>

        <Blank />
        <Space style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="primary" disabled={!platformAuthed} onClick={submit} loading={loading}>
            {dict('ACTION_SAVE')}
          </Button>
        </Space>
      </FormCard>
    </>
  );
};

export default AuthForm;
