import { useAppContext } from '@/contexts/AppContext';
import { getKeywordCategoryCount } from '@/services/keyword_category';
import { Format } from '@/utils/format';
import { Menu, type MenuProps } from '@antd';
import { useCreation, useRequest } from 'ahooks';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import React from 'react';
import { UsageScopeEnum } from '../../const.var';
import { dict } from '@/hooks/useChangeLocale';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
  selectable?: boolean,
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    selectable,
    style: {
      paddingLeft: key == UsageScopeEnum.ALL ? undefined : 30,
    },
  } as MenuItem;
}

type LeftNavProp = {
  scopeType: UsageScopeEnum;
  onScopeTypeChange: (v: UsageScopeEnum) => void;
  event$: EventEmitter<any>;
};

const getCount = (title, count) => {
  if (count)
    return (
      <div title={dict('KEYWORD_TOTAL_TIP', { count })}>
        {title}（{Format.toFirendlyNumText(count)}）
      </div>
    );
  return <div title={dict('NO_KEYWORD_CLASSFICATION')}>{title}</div>;
};
const LeftNav = ({ scopeType, onScopeTypeChange, event$ }: LeftNavProp) => {
  const { user } = useAppContext();
  const { data: typeCount, refresh } = useRequest(() => getKeywordCategoryCount(user?.bid), { refreshDeps: [user] });

  const items: MenuProps['items'] = useCreation(() => {
    return [
      getItem(getCount(dict('SEMANTIC_CLASSIFICATION'), typeCount?.allCount), UsageScopeEnum.ALL),
      getItem(getCount(dict('CONVERSATION'), typeCount?.sessionCount), UsageScopeEnum.Conversation),
      getItem(getCount(dict('COMMENT'), typeCount?.commentCount), UsageScopeEnum.Comment),
      getItem(getCount(dict('BARRAGE'), typeCount?.barrageCount), UsageScopeEnum.BulletChat),
    ];
  }, [typeCount]);

  event$.useSubscription((action) => {
    if (action == 'refreshCount') refresh();
  });

  return (
    <Menu
      style={{ width: 200 }}
      className="page-left-nav"
      selectedKeys={[`${scopeType}`]}
      onClick={(e) => {
        const key = Number(e.key) as unknown;
        onScopeTypeChange(key as UsageScopeEnum);
      }}
      items={items}
    />
  );
};

export default LeftNav;
