import { Spin } from '@antd';
import React, { Key, ReactNode } from 'react';
import styles from './style.less';
import { useMemoizedFn, useSafeState } from 'ahooks';
import classnames from 'classnames';

export type CardChildItem = {
  title: string | ReactNode;
  value: Key;
  [key: string]: any;
};

export type CardItem = {
  title: string | ReactNode;
  child: CardChildItem[];
  [key: string]: any;
};

type CardSelectProps = {
  items: CardItem[];
  columns?: number;
  loading?: boolean;
};

export default (): [React.FC<any>, number | undefined] => {
  const [selectedIndex, setSelectedIndex] = useSafeState<number>(0);

  const CardSelect: React.FC<CardSelectProps> = useMemoizedFn(({ items, loading = false, columns = 6 }) => {
    return (
      <Spin spinning={loading}>
        <div className={styles.wrap} style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
          {items.map(({ title, child }, index) => (
            <div
              className={classnames([
                styles.card,
                {
                  [styles.actived]: selectedIndex === index,
                },
              ])}
              key={index}
              onClick={() => setSelectedIndex(index)}
            >
              <div className={styles.title}>{title}</div>
              <div className={styles.content}>
                <div className={styles['statisic-data-card-other']}>
                  {child?.map((item: any, index) => {
                    return (
                      <div key={index}>
                        {item?.title}: <span className={styles.high}>{item.value}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Spin>
    );
  });

  return [CardSelect, selectedIndex];
};
