import { Form, Select, Space, Table, Typography } from '@antd';
import { useCreation, useMemoizedFn, useRequest } from 'ahooks';
import { FormInstance } from 'antd/lib/form';
import { Function1, Function2, get, isEmpty, uniqueId } from 'lodash';
import React, { Key, createContext, useContext, useEffect } from 'react';
import style from './style.less';
import { OrgTreeSelect } from '@/components/Form/Field/select/OrgTreeSelect';
import { getResourceAttrList } from '@/services/resource-attr';
import { ResourceAttrData } from '@/services/resource-attr/type';
import { ArrLabelText } from '@/components/Form/Field/Text';
import { useAppContext } from '@/contexts/AppContext';
import { getOrganizationList } from '@/services/organization';
import { Validator } from '@/utils/validator';
import { dict } from '@/hooks/useChangeLocale';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  dataIndex: string;
  record: any;
  index: number;
  children: React.ReactNode;
  readonly: boolean;
  updateRecord: Function2<any, number, void>;
}

/**
 * 单元处理
 * @param param0
 * @returns
 */
const EditableCell = React.memo(
  ({ dataIndex, record, index, children, readonly, updateRecord, ...restProps }: EditableCellProps) => {
    const { valueDictMap = {}, organizations = [] } = useContext(DataPermissionContext);

    const Comp = useCreation(() => {
      const selectConfig = { style: { width: '100%' } };
      const { resourceName, attrName } = record || {};

      let comp;
      if (dataIndex == 'resourceName') {
        const options = Object.entries(valueDictMap).map(([value, obj]) => ({
          label: get(obj, 'name'),
          value,
        }));
        comp = readonly ? (
          <ArrLabelText value={resourceName} list={options} />
        ) : (
          <Form.Item
            name={[index, dataIndex]}
            rules={[
              {
                validator(_, value) {
                  if (!value || !options.find((v) => v.value == value))
                    return Promise.reject(dict('ROLE_CONFIG_ATTR_MISSING'));
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              {...selectConfig}
              placeholder={dict('RESOURCE_OBJ')}
              showSearch={false}
              options={options}
              onChange={(key) => {
                updateRecord(
                  { ...record, attrName: undefined, op: undefined, bindData: undefined, [dataIndex]: key },
                  index,
                );
              }}
            />
          </Form.Item>
        );
      } else if (dataIndex == 'attrName') {
        const options = Object.entries(get(valueDictMap, `${resourceName}.scope`, {})).map(([value, obj]) => ({
          label: get(obj, 'name'),
          value,
        }));
        comp = readonly ? (
          <ArrLabelText value={attrName} list={options} />
        ) : (
          <Form.Item
            name={[index, dataIndex]}
            dependencies={[index, 'resourceName']}
            rules={[{ required: true, message: dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('ATTRIBUTE_SCOPE') }) }]}
          >
            <Select
              {...selectConfig}
              placeholder={dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('ATTRIBUTE_SCOPE') })}
              showSearch={false}
              options={options}
              onChange={(key) => {
                updateRecord({ ...record, op: undefined, bindData: undefined, [dataIndex]: key }, index);
              }}
            />
          </Form.Item>
        );
      } else if (dataIndex == 'op') {
        const dictMaping = { eq: dict('EQUAL'), noteq: dict('NOT_EQUAL') };
        const options = get(valueDictMap, `${resourceName}.scope.${attrName}.op`, []).map((value) => ({
          value,
          label: dictMaping[value],
        }));
        comp =
          attrName == 'all' ? (
            '-'
          ) : readonly ? (
            <ArrLabelText value={record.op} list={options} />
          ) : (
            <Form.Item
              name={[index, dataIndex]}
              rules={[{ required: true, message: dict('PLEASE_SELECT_MULTIPLE_NAME', { name: 'OPERATOR_SYMBOL' }) }]}
            >
              <Select
                {...selectConfig}
                placeholder={dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('OPERATOR_SYMBOL') })}
                showSearch={false}
                options={options}
                onChange={(key) => {
                  updateRecord({ ...record, [dataIndex]: key }, index);
                }}
              />
            </Form.Item>
          );
      } else if (dataIndex == 'bindData') {
        comp =
          attrName == 'all' ? (
            '-'
          ) : readonly ? (
            <ArrLabelText value={record.bindData} list={organizations.map((v) => ({ value: v.id, label: v.name }))} />
          ) : (
            <Form.Item
              name={[index, dataIndex]}
              rules={[{ required: true, message: dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('ORG_STRUCTURE') }) }]}
            >
              <OrgTreeSelect
                style={{ width: '100%' }}
                placeholder={dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('ORG_STRUCTURE') })}
                organizationList={organizations}
                multiple
                onChange={(key) => {
                  updateRecord({ ...record, [dataIndex]: key }, index);
                }}
              />
            </Form.Item>
          );
      }
      return comp;
    }, [dataIndex, record, index, readonly, updateRecord, valueDictMap, organizations]);

    return (
      <td {...restProps} className={style['custom-rule-td']}>
        {Comp || children}
      </td>
    );
  },
);

type DataPermissionSetProps = {
  value?: any[];
  onChange?: Function1<Array<unknown>, void>;
  form?: FormInstance;
  readonly?: boolean;
  teamId?: Key;
  roleIds?: Key[];
};

const DataPermissionContext = createContext<{ valueDictMap: any; organizations?: any[] }>({} as any);
export const DataPermissionSet: React.FC<DataPermissionSetProps> = ({
  value,
  onChange,
  form,
  readonly,
  teamId,
  roleIds,
}) => {
  const { user } = useAppContext();
  //列表数据
  const dataSource: any[] = useCreation(() => {
    return (value || []).map((item) => ({
      ...item,
      teamId,
      _id: item._id || uniqueId(),
    }));
  }, [value]);

  //组织数据
  const { data: organizations } = useRequest(() => getOrganizationList(user?.bid), {
    refreshDeps: [user],
  });

  const updateRecord = useMemoizedFn((newRecord, index) => {
    const newValue = [...dataSource];
    newValue[index] = newRecord;
    onChange?.([...newValue]);
  });

  const getOnCell = (dataIndex) => (record, index) =>
    ({
      record,
      dataIndex,
      index,
      readonly,
      updateRecord,
    }) as any;

  const addRow = () => {
    onChange?.([...dataSource, { _id: uniqueId() }]);
  };

  const { data, loading } = useRequest(
    async () => {
      const data: ResourceAttrData[] = await getResourceAttrList(
        Validator.isNilEmpty(roleIds) ? teamId : undefined,
        roleIds,
      );
      return convertResourceAttrData(data);
    },
    { refreshDeps: [teamId, roleIds] },
  );

  useEffect(() => {
    if (!readonly && isEmpty(dataSource)) {
      addRow();
    }
    form?.setFieldsValue([...dataSource]);
  }, [dataSource, readonly, data, value]);

  return (
    <>
      <DataPermissionContext.Provider value={{ valueDictMap: data, organizations }}>
        <Form form={form} component={false}>
          <Table
            loading={loading}
            style={{ width: '100%' }}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            size="small"
            rowKey="_id"
            dataSource={dataSource}
            columns={
              [
                {
                  title: dict('RESOURCE'), // 资源
                  width: 150,
                  align: 'center',
                  dataIndex: 'resourceName',
                  onCell: getOnCell('resourceName'),
                },
                {
                  title: dict('ATTRIBUTE_SCOPE'), // 属性范围
                  width: 150,
                  align: 'center',
                  dataIndex: 'attrName',
                  onCell: getOnCell('attrName'),
                },
                {
                  title: dict('OPERATE_TYPE'), //操作类型
                  width: 150,
                  align: 'center',
                  dataIndex: 'op',
                  onCell: getOnCell('op'),
                },
                {
                  title: dict('ORG_STRUCTURE'), // 组织架构
                  align: 'center',
                  dataIndex: 'bindData',
                  onCell: getOnCell('bindData'),
                },
                readonly
                  ? false
                  : {
                      title: dict('ACTION_OPERATE'),
                      align: 'center',
                      width: 80,
                      render: (_: any, record) => {
                        return (
                          <Typography.Link
                            disabled={get(dataSource, 'length', 0) <= 1}
                            onClick={() => {
                              onChange?.(dataSource.filter((v) => v._id != record._id));
                            }}
                          >
                            {dict('ACTION_REMOVE')}
                          </Typography.Link>
                        );
                      },
                    },
              ].filter(Boolean) as any[]
            }
            pagination={false}
          />
        </Form>
        {!readonly && (
          <Space style={{ marginTop: 5 }}>
            <a onClick={addRow}>{dict('ACTION_ADD')}</a>
          </Space>
        )}
      </DataPermissionContext.Provider>
    </>
  );
};

const convertResourceAttrData = (datas: ResourceAttrData[]) => {
  const objCache = {};
  datas
    .sort((v1, v2) => v1.seq - v2.seq)
    .forEach((data) => {
      const { resourceName, resourceDisplayName, name, displayName, op } = data;
      const obj = (objCache[resourceName] = objCache[resourceName] || {});
      obj.name = resourceDisplayName;

      const scope = (obj.scope = obj.scope || {});
      const item = (scope[name] = scope[name] || {});
      item['name'] = displayName;
      item['op'] = op;
    });
  return objCache;
};
