import RecentDateBtnPick from '@/components/Form/RecentDateBtnPick';
import { useMemoizedFn, useSafeState } from 'ahooks';
import dayjs from 'dayjs';
import { DATE_FORMAT_STR } from '@/utils/dateUtil';
import { DatePicker } from '@antd';

const { RangePicker } = DatePicker;

export default (): [React.FC, string[]] => {
  const today = dayjs();
  const endDay = today.subtract(1, 'day'); //T+1
  const before30Day = endDay.subtract(30, 'day');
  const rangeDate: string[] = [before30Day.format(DATE_FORMAT_STR), endDay.format(DATE_FORMAT_STR)];
  const [date, setDate] = useSafeState(rangeDate);

  const Picker = useMemoizedFn((props) => {
    return (
      <>
        <RecentDateBtnPick value={date} onChange={setDate} showTotal={false} />
        <RangePicker
          style={{ width: 200, marginLeft: 10 }}
          value={[dayjs(date[0]), dayjs(date[1])]}
          onChange={(e) => {
            setDate(e ? e.map((item) => (item as any).format(DATE_FORMAT_STR)) : rangeDate);
          }}
          {...props}
        />
      </>
    );
  });

  return [Picker, date];
};
