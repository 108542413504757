import { EXPIRED_QR_CODE, FAULT_IMAGE, MEDIA_ID_MAPPING, MEDIA_KEY_ENUM } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import { RefreshLine } from '@/icons';
import { getAuthLoginData, refreshQrCode } from '@/services/auth';
import { getShareLoginData, refreshShareQrCode } from '@/services/share';
import { UserType } from '@/services/user/type';
import { LoadingOutlined } from '@ant-design/icons';
import { App, Button, Modal, Spin, Typography } from '@antd';
import { useBoolean, useInterval, useResetState, useSafeState } from 'ahooks';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { AccountAuthInfoStatusMessage, LoginAuthStatuEnum } from '../../../const.var';
import style from '../style.less';
import { DouyinScanCodeWrap } from './douyin/ScanCodeWrap';
import { KuaishouScanCodeWrap } from './kuishou/ScanCodeWrap';
import { ShipingHaoScanCodeWrap } from './shipinghao/ScanCodeWrap';
import { XiaoHongShuScanCodeWrap } from './xiaohongshu/ScanCodeWrap';
import { LoginAuthType } from '@/services/auth/type';
import { dict } from '@/hooks/useChangeLocale';

type ModalProp = {
  isModalOpen: boolean;
  onHandleCancel: VoidFunction;
  onHandleSuccess: (res) => void;
  setQrStatus: (status) => void;
  isShareAuth?: boolean; //是否分享授权
  restartLaunchLoginAuthAction: (id) => Promise<any>; //重新设置登录授权
  loginAuthType?: LoginAuthType; //抖音有创建授权和登录授权
  media: MEDIA_KEY_ENUM; //授权平台
  uuid?: string; //分享平台，快手授权传
  id: string;
};

const pollingTime = 1000;

//刷新二维码
const getRefrshQrCodeAction = (isShareEnv: boolean) => {
  return isShareEnv ? refreshShareQrCode : refreshQrCode;
};

//获取扫码状态
const getQueryQrStatusAction = (media: MEDIA_KEY_ENUM, isShareEnv: boolean, user: UserType, uuid?: string) => {
  return (taskId, platformType?: LoginAuthType) => {
    //快手及其他平台
    const mediaCode = MEDIA_ID_MAPPING[media];
    return isShareEnv
      ? getShareLoginData(taskId, mediaCode, uuid, platformType)
      : getAuthLoginData(user.bid, mediaCode, user.tenantId, taskId, platformType);
  };
};

const getScanCodeWrap = (media: MEDIA_KEY_ENUM) => {
  if (media == MEDIA_KEY_ENUM.DOUYIN) return DouyinScanCodeWrap;
  if (media == MEDIA_KEY_ENUM.WEIXIN) return ShipingHaoScanCodeWrap;
  if (media == MEDIA_KEY_ENUM.XIAOHONGSHU) return XiaoHongShuScanCodeWrap;
  return KuaishouScanCodeWrap;
};

const ScanCodeModal = ({
  id,
  isModalOpen,
  onHandleCancel,
  onHandleSuccess,
  setQrStatus,
  restartLaunchLoginAuthAction,
  loginAuthType,
  media,
  uuid,
  isShareAuth = false,
}: ModalProp) => {
  const { message } = App.useApp();
  const [pollingRes, setPollingRes, resetPollingRes] = useResetState<any>({});
  const { status } = pollingRes; //状态

  const { user } = useAppContext();
  const [loading, { setTrue: setLoading, setFalse: hideLoading }] = useBoolean(true);
  const [refreshQrError, { setTrue: showRefreshQrError, setFalse: hideRefreshError }] = useBoolean(false); //刷新二维码异常

  const [polling, setPolling] = useState<number | undefined>(); //延迟时间，如果为undifined，停止轮训
  const startPolling = () => setPolling(pollingTime);
  const stopPolling = () => setPolling(undefined);
  const needLaunchBrowser = refreshQrError || status == LoginAuthStatuEnum.error;

  const [errorTip, setErrorTip] = useSafeState('');

  const QrWrap = getScanCodeWrap(media);

  useEffect(() => {
    setLoading();
    if (isModalOpen) {
      startPolling();
    } else {
      resetPollingRes();
      stopPolling();
      hideRefreshError();
    }
  }, [isModalOpen]);

  useEffect(() => {
    setLoading();
    if (!status) return;
    setQrStatus(pollingRes);
    if (status == LoginAuthStatuEnum.init) {
      setLoading(); // 初始化中 loading的状态
      return;
    }

    setErrorTip(get(AccountAuthInfoStatusMessage, status));
    if ([LoginAuthStatuEnum.expired, LoginAuthStatuEnum.refused, LoginAuthStatuEnum.error].includes(status)) {
      //已过期 ，停止轮询
      stopPolling();
    } else if (status == LoginAuthStatuEnum.success) {
      message.success(dict('AUTHORIZATION_SUCCESS'));
      onHandleSuccess(pollingRes);
    } else if ([LoginAuthStatuEnum.accountError, LoginAuthStatuEnum.accountBindError].includes(status)) {
      message.error(get(pollingRes, 'message', dict('SCAN_QR_ERROR')));
      onHandleCancel();
    }

    hideLoading();
  }, [pollingRes]);

  useInterval(async () => {
    try {
      const queryAuthLodgin = getQueryQrStatusAction(media, isShareAuth, user as UserType, uuid);
      const res = await queryAuthLodgin(id, loginAuthType);
      setPollingRes(res);
    } catch (error: any) {
      message.error(get(error, 'status.message'));
      stopPolling();
    }
  }, polling);

  const refresh = () => {
    setLoading();
    if (needLaunchBrowser) {
      //重启浏览器对象
      hideRefreshError();
      resetPollingRes();
      restartLaunchLoginAuthAction(id)
        .then(startPolling)
        .catch((error) => {
          message.error(get(error, 'message') || get(error, 'status.message'));
        });
    } else {
      const refreshQrCodeAction = getRefrshQrCodeAction(isShareAuth);
      refreshQrCodeAction(id, MEDIA_ID_MAPPING[media], loginAuthType)
        .then((data) => {
          if (data.code == 'E0') {
            startPolling();
          } else {
            throw data;
          }
        })
        .catch((error) => {
          message.error(get(error, 'message') || get(error, 'status.message'));
          showRefreshQrError();
        });
    }
  };

  return (
    <Modal
      title={dict('REAUTHORIZATION')}
      open={isModalOpen}
      destroyOnClose
      onOk={onHandleCancel}
      onCancel={onHandleCancel}
      maskClosable={false}
      width={1200}
      maskStyle={{ background: 'rgba(0,0,0,0.9)' }}
      bodyStyle={{ display: 'flex', justifyContent: 'center' }}
      footer={
        <Button type="primary" onClick={onHandleCancel}>
          {dict('CLOSE')}
        </Button>
      }
    >
      <QrWrap>
        <Spin
          spinning={loading}
          tip={<div style={{ color: '#999', marginTop: 20 }}>{dict('ACCOUNT_STATUS_INIT')}</div>}
        >
          <div className={style['qr-wrapper']}>
            <img src={loading ? FAULT_IMAGE : pollingRes.qrcode || EXPIRED_QR_CODE} alt="" />
            {[LoginAuthStatuEnum.expired, LoginAuthStatuEnum.refused, LoginAuthStatuEnum.error].includes(status) ? (
              <div title={needLaunchBrowser ? dict('CLICK_TO_REAUTHORIZE') : dict('CLICK_TO_RELOAD')} onClick={refresh}>
                <div style={{ width: '100%', height: 90, textAlign: 'center', lineHeight: '22px', fontSize: 14 }}>
                  <>
                    <RefreshLine style={{ fontSize: 32 }} />
                    <div>{errorTip}</div>
                    <div>{needLaunchBrowser ? dict('CLICK_TO_REAUTHORIZE') : dict('CLICK_TO_RELOAD')}</div>
                  </>
                </div>
              </div>
            ) : null}
          </div>
          {refreshQrError ? (
            <Typography.Text type="danger">
              <a onClick={refresh}> {dict('TRY_REFRESH_QRCODE_TIP')} </a>
            </Typography.Text>
          ) : !loading && pollingRes.message ? (
            <Typography.Text>
              {polling ? <LoadingOutlined /> : null} {pollingRes.message}
            </Typography.Text>
          ) : null}
        </Spin>
      </QrWrap>
    </Modal>
  );
};

export default ScanCodeModal;
