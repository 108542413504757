import ChartCore from '@/components/ChartCore';
import { chortColors } from '@/components/ChartCore/theme';
import { Format } from '@/utils/format';
import { Validator } from '@/utils/validator';
import { useCreation, useRequest } from 'ahooks';
import { isNil, sum } from 'lodash';
import { Spin } from '@antd';
import { getHomeUserProvince } from '@/services/home';
import { dict } from '@/hooks/useChangeLocale';

const typeConfig = {
  province: {
    value: 'metrics',
    name: 'dimensions',
  },
};

const Chart = ({ bid, type }: { bid?: number; type: 'province' | 'crowd' | 'city' | 'interest' }) => {
  const { data, loading } = useRequest(
    async () => {
      if (isNil(bid)) return undefined;

      return getHomeUserProvince(bid);
    },
    {
      refreshDeps: [bid],
    },
  );
  // 获取相应的配置
  const config = useCreation(() => typeConfig[type], [type]);

  const disposedData = useCreation(
    () =>
      (data || []).reduce((obj, next) => {
        obj[next[config.name]] = next[config.value];
        return obj;
      }, {}),
    [config, data],
  );

  const total = sum(Object.values(disposedData));

  const options = useCreation(
    () => ({
      tooltip: {
        trigger: 'axis',
        formatter: `{b}： {c}${dict('PEOPLE')}`,
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {},
      grid: {
        top: 0,
        left: 30,
        right: 60,
        bottom: 0,
      },
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        offset: 10,
        boundaryGap: ['20%', '20%'],
        inverse: true,
        data: Object.keys(disposedData),
      },
      series: [
        {
          type: 'bar',
          realtimeSort: true,
          label: {
            show: true,
            formatter: function (params) {
              return Format.toPercent(total ? params.value / total : 0);
            },
          },
          data: Object.values(disposedData).map((value, index) => ({
            value,
            itemStyle: {
              color: chortColors[index % chortColors.length],
            },
          })),
        },
      ],
    }),
    [data],
  );

  return (
    <Spin spinning={loading}>
      <ChartCore option={options} notMerge empty={Validator.isNilEmpty(data)} style={{ height: 300 }} />
    </Spin>
  );
};

export default Chart;
