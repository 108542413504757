import { dict } from '@/hooks/useChangeLocale';
import { themeConfig } from '@/layouts/const/themes';
import { Format } from '@/utils/format';
import { generate } from '@ant-design/colors';
import { get, sum } from 'lodash';

export const getTrendLineChatOption = (data, labels, fieldMap, showLegend = true, color?: string[]) => ({
  tooltip: {
    trigger: 'axis',
  },
  color,
  legend: showLegend && {},
  grid: {
    top: 30,
    left: 50,
    right: 50,
    bottom: 60,
  },
  dataZoom:
    data.length > 10
      ? [
          { show: true, maxValueSpan: 20, minValueSpan: 15, start: 90 },
          { type: 'inside', maxValueSpan: 20, minValueSpan: 15 },
        ]
      : undefined,
  xAxis: {
    type: 'category',
    boundaryGap: false,
    offset: 10,
    data: labels,
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: '{value} ',
    },
  },
  series: Object.entries(fieldMap).map(([filed, name]) => ({
    name,
    // smooth: true,
    areaStyle: {
      opacity: 0,
    },
    type: 'line',
    data: data.map((v) => v[filed]),
  })),
});

export const getFunnelChartOptions = (data, unit, fieldMap) => {
  let maxV = 0;
  const list = Object.keys(data || {})
    .filter((key) => fieldMap[key])
    .map((key) => {
      const value: number = get(data, key, 0);
      maxV = Math.max(maxV, value);
      return {
        value,
        name: get(fieldMap, key),
      };
    })
    .sort((v1: any, v2: any) => v1.value - v2.value)
    .map((v) => ({
      ...v,
      convert: maxV ? Format.toPercent(v.value / maxV) : 0,
    }));

  return {
    series: [
      {
        type: 'funnel',
        min: 0,
        max: 100,
        maxSize: '100%',
        sort: 'descending',
        // colorBy: 'series',
        color: generate(themeConfig.colorPrimary),
        label: {
          show: true,
          position: 'inside',
          formatter: function (params) {
            return `${params.data.name} ${params.data.value}${unit} (${params.data.convert})`;
          },
        },
        data: list,
      },
    ],
  };
};

export const getPieChartOptions = (data) => {
  return {
    legend: {
      left: 'center',
    },
    tooltip: {
      formatter: '{b}： {c}人({d}%)',
    },
    title: {
      text: dict('FANS_COUNT'),
      subtext: Format.toAmountNumber(sum(data.map((v) => v.value))),
      left: 'center',
      top: 'center',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '60%'],
        label: {
          show: true,
          formatter: `{b} {c}${dict('PEOPLE')}`,
        },
        data,
      },
    ],
  };
};
