import { Button, Flex, Tag } from '@antd';
import { useRolePickModal } from '../modal/useRolePickerModal';
import { getRoleList } from '@/services/role';
import { useCreation, useRequest } from 'ahooks';
import { Key } from 'react';
import { Function1, isEmpty } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';

type RoleConfigField = {
  value?: Key[];
  onChange?: Function1<any, void>;
  readonly?: boolean;
  id?: string;
};
export default ({ value, onChange, id, readonly }: RoleConfigField) => {
  const [RolePickModal, openModal] = useRolePickModal();
  const { user } = useAppContext();
  const { data: roles } = useRequest(() => getRoleList(user?.bid));
  const roleMap = useCreation(
    () =>
      (roles || []).reduce((map, role) => {
        map[role.id] = role.roleName;
        return map;
      }, {}),
    [roles],
  );

  return (
    <>
      <Flex wrap="wrap" gap={10} id={id}>
        {isEmpty(value) && readonly
          ? '-'
          : value?.map((v) => (
              <Tag
                closeIcon={!readonly}
                key={v}
                bordered={false}
                style={{ lineHeight: '25px', fontSize: 13 }}
                onClose={() => onChange?.(value?.filter((roleId) => roleId != v))}
              >
                {roleMap[`${v}`]}
              </Tag>
            ))}

        {!readonly && (
          <Button type="dashed" size="small" icon={<PlusOutlined />} onClick={() => openModal(value || [])}>
            {dict('ACTION_ADD')}
          </Button>
        )}
      </Flex>
      <RolePickModal roles={roles} onRoleSelect={onChange} />
    </>
  );
};
