import { Input } from '@antd';
import { TextAreaProps } from 'antd/es/input';
import { trim, union } from 'lodash';

type KeywordTextAreaProps = {
  value?: string[];
  onChange?: (v: string[]) => void;
} & TextAreaProps;

export const KeywordTextArea: React.FC<KeywordTextAreaProps> = ({ value, readOnly, onChange, ...props }) => {
  return (
    <Input.TextArea
      {...props}
      rows={10}
      value={value?.join('\n')}
      readOnly={readOnly}
      onChange={(e) => {
        onChange?.(e.target.value.split('\n'));
      }}
      onBlur={(e) => {
        const keywords = e.target.value.split('\n').filter((v) => trim(v));
        onChange?.(union(keywords));
      }}
    />
  );
};
