import styles from './style.less';
import rank1 from '@/assets/rank1.svg';
import rank2 from '@/assets/rank2.svg';
import rank3 from '@/assets/rank3.svg';
import style from '@/pages/workbench/outline/style.less';
import { Card, Radio, Divider, Dropdown, Spin } from '@antd';
import SortIcon from '@/components/Icon/Sort';
import { useRequest, useSafeState } from 'ahooks';
import { isNil } from 'lodash';
import { getHomeSort } from '@/services/home';
import { RANK_ORG_TYPE, RANK_TYPE } from '@/services/home/type';
import { dict } from '@/hooks/useChangeLocale';

const rankList = [rank1, rank2, rank3];
const rankConfig = {
  [RANK_TYPE.LIVE]: {
    title: dict('LIVE_PLAY_TOP10'),
    countConfig: [
      {
        name: dict('LIVE_TOTAL'),
        key: 'liveTotal',
      },
      {
        name: dict('LIVE_INDICATOR_WATCH_UV'),
        key: 'viewerTotal',
      },
    ],
  },
  [RANK_TYPE.VIDEO]: {
    title: dict('VIDEO_PLAY_TOP10'),
    countConfig: [
      {
        name: dict('VIDEO_TOTAL'),
        key: 'itemTotal',
      },
      {
        name: dict('PLAY_COUNT'),
        key: 'playTotal',
      },
    ],
  },
  [RANK_TYPE.FANS]: {
    title: dict('FANS_SORT_TOP10'),
    countConfig: [
      {
        name: dict('FANS_TOTAL'),
        key: 'fansTotal',
      },
      {
        name: dict('NEW_FANS'),
        key: 'netFans',
      },
    ],
  },
  [RANK_TYPE.CLUE]: {
    title: dict('LEADS_RORT_TOP10'),
    countConfig: [
      {
        name: dict('CLUE_TOTAL'),
        key: 'cluesTotal',
      },
      {
        name: dict('NEW_LEADS'),
        key: 'netClues',
      },
    ],
  },
};

export const Filter = ({ config, value, onChange }) => {
  const menus = config.map((item) => ({
    key: item.key,
    label: dict('BY_NAME', { name: item.name }),
    children: [
      {
        key: 'asc',
        label: dict('ASC'),
      },
      {
        key: 'desc',
        label: dict('DESC'),
      },
    ].map((_item) => ({
      ..._item,
      key: `${item.key}-${_item.key}`,
    })),
  }));

  return (
    <div className={styles.filter}>
      <Radio.Group
        buttonStyle="outline"
        optionType="button"
        value={value.orgType}
        options={[dict('THEATER_COMMAND'), dict('DEALER')].map((item, index) => ({
          label: item,
          value: [RANK_ORG_TYPE.REGION, RANK_ORG_TYPE.DEALER][index],
        }))}
        onChange={(e) =>
          onChange?.({
            ...value,
            orgType: e.target.value,
          })
        }
      />
      <Divider className={styles.divider} type="vertical" />
      <Dropdown
        menu={{
          items: menus,
          selectedKeys: [value?.sort?.join('-')],
          onClick: (e) => {
            onChange?.({
              ...value,
              sort: e.key.split('-'),
            });
          },
        }}
      >
        <div className={styles.icon}>
          <SortIcon />
        </div>
      </Dropdown>
    </div>
  );
};

const List = ({ searchParams, type }: { searchParams: any; type: RANK_TYPE }) => {
  const { title, countConfig } = rankConfig[type];
  const [filter, setFilter] = useSafeState({
    orgType: RANK_ORG_TYPE.REGION,
    sort: [countConfig[0].key, 'desc'],
  });

  const { data, loading } = useRequest(
    async () => {
      if (isNil(searchParams.bid) || isNil(searchParams.tenantId)) return undefined;
      const [sort, direction] = filter.sort;

      return getHomeSort({
        ...searchParams,
        ...filter,
        sort,
        direction,
        type,
      });
    },
    {
      refreshDeps: [searchParams, filter, type],
    },
  );

  return (
    <Spin spinning={loading}>
      <Card
        title={title}
        className={style['chart-card']}
        extra={<Filter value={filter} onChange={setFilter} config={countConfig} />}
      >
        <div className={styles.list}>
          {(data || []).map((item: any, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.rank}>
                {index < 3 ? (
                  <img src={rankList[index]} alt="" className={styles.icon} />
                ) : (
                  <div className={styles.num}>{index + 1}</div>
                )}
              </div>
              <div className={styles.name}>{item.name}</div>
              <div className={styles.count}>
                {countConfig[0].name}：{item[countConfig[0].key]}
              </div>
              <div className={styles.count}>
                {countConfig[1].name}：{item[countConfig[1].key]}
              </div>
            </div>
          ))}
        </div>
      </Card>
    </Spin>
  );
};

export default List;
