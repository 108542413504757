import { FormOpType } from '@/constants/enum';
import { useAppContext } from '@/contexts/AppContext';
import useTableRequest, { getColumnSearchProps, getTableColumn } from '@/hooks/useTableRequest';
import { Delete, Edit, View } from '@/icons';
import { createOrganization, delOrganization, getOrganizationPages, updateOrganization } from '@/services/organization';
import { OrganizationModel } from '@/services/organization/type';
import { App, Button, Space, Table, TableColumnType, Typography } from '@antd';
import { useBoolean } from 'ahooks';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { get } from 'lodash';
import { EventType } from '..';
import { OrgConfigType, OrgFieldDiffMapping, OrganizationEnum } from '../const.var';
import ModalFormStore from './ModalForm';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { dict } from '@/hooks/useChangeLocale';

type ListProps = {
  type: OrganizationEnum;
  event$: EventEmitter<EventType>;
};
const { Link } = Typography;

const List = ({ type, event$ }: ListProps) => {
  const { modal, message } = App.useApp();
  const { user } = useAppContext();
  const [loading, { setTrue: setLoading, setFalse: hideLoading }] = useBoolean();
  const config: OrgConfigType = OrgFieldDiffMapping[type];

  const { tableProps, refresh: refreshTable } = useTableRequest(
    async ({ pageIndex, pageSize, filters }) => {
      return getOrganizationPages({
        bid: user?.bid,
        searchWord: get(filters, 'name[0]'),
        type,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [user, type] },
  );

  const refresh = () => {
    refreshTable();
    event$.emit({ action: 'refreshOrg' });
  };

  const { getBtnPermissionConfig } = usePermission();
  //刷新侧边统计数
  return (
    <>
      <Space style={{ marginBottom: 10 }}>
        <ModalFormStore
          orgType={type}
          formType={FormOpType.CREATE}
          {...getBtnPermissionConfig(AUTH.ORGAINIZATION.CREATE)}
          onFinish={async (values) => {
            try {
              await createOrganization({ ...values, bid: user?.bid, type });
              message.success(dict('CREATE_SUCCESS'));
              refresh();
              return true;
            } catch (error: any) {
              message.error(get(error, 'status.message', dict('OPERATION_FAILURE')));
            }
          }}
        >
          <Button type="primary" {...getBtnPermissionConfig(AUTH.ORGAINIZATION.CREATE)}>
            {dict('ACTION_NEW')}
          </Button>
        </ModalFormStore>
      </Space>
      <Table
        {...tableProps}
        loading={tableProps.loading || loading}
        columns={
          [
            config.code.show
              ? getTableColumn({
                  title: config.code.alias,
                  align: 'left',
                  dataIndex: 'code',
                })
              : null,
            getTableColumn({
              title: config.name.alias,
              dataIndex: 'name',
              ...getColumnSearchProps({
                dataIndex: 'name',
              }),
            }),
            config.treeName.show
              ? getTableColumn({
                  title: dict('AFFILIATION_ORG'),
                  ellipsis: true,
                  dataIndex: 'treeName',
                })
              : null,
            getTableColumn({
              title: dict('CREATE_TIME'),
              dataIndex: 'createdAt',
            }),

            getTableColumn({
              title: dict('CREATOR'),
              dataIndex: 'createdUserId',
              render: () => 'admin',
            }),

            getTableColumn({
              title: dict('ACTION_OPERATE'),
              align: 'center',
              render: (_, record) => {
                return (
                  <Space>
                    <ModalFormStore
                      orgType={type}
                      {...getBtnPermissionConfig(AUTH.ORGAINIZATION.VIEW)}
                      record={record}
                      formType={FormOpType.VIEW}
                    >
                      <Link {...getBtnPermissionConfig(AUTH.ORGAINIZATION.VIEW)}>
                        <View className="action-icon" />
                      </Link>
                    </ModalFormStore>
                    <ModalFormStore
                      orgType={type}
                      record={record}
                      formType={FormOpType.EDIT}
                      {...getBtnPermissionConfig(AUTH.ORGAINIZATION.EDIT)}
                      onFinish={async (values) => {
                        try {
                          await updateOrganization({ id: record.id, bid: record.bid, type: record.type, ...values });
                          message.success(dict('EDIT_SUCCESS'));
                          refresh();
                          return true;
                        } catch (error: any) {
                          message.error(get(error, 'status.message', dict('OPERATION_FAILURE')));
                        }
                      }}
                    >
                      <Link {...getBtnPermissionConfig(AUTH.ORGAINIZATION.EDIT)}>
                        <Edit className="action-icon" />
                      </Link>
                    </ModalFormStore>

                    <Link
                      {...getBtnPermissionConfig(AUTH.ORGAINIZATION.DELETE)}
                      onClick={() => {
                        modal.confirm({
                          title: dict('DELETE_ORG_CONFIRM_TIP', { name: record.name }),
                          content: dict('DELETE_ORG_CONFIRM_SUB_TIP'),
                          onOk: async () => {
                            try {
                              setLoading();
                              await delOrganization(record.id);
                              refresh();
                            } catch (error: any) {
                              message.error(get(error, 'status.message', dict('DELETE_FAILURE')));
                            }
                            hideLoading();
                          },
                        });
                      }}
                    >
                      <Delete className="action-icon" />
                    </Link>
                  </Space>
                );
              },
            }),
          ].filter((v) => v) as TableColumnType<OrganizationModel>[]
        }
      />
    </>
  );
};

export default List;
