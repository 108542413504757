import { dict } from '@/hooks/useChangeLocale';
import { RoleData } from '@/services/role/type';
import { Flex, Modal, ModalProps, Transfer } from '@antd';
import { useBoolean, useCreation, useMemoizedFn } from 'ahooks';
import { Function1 } from 'lodash';
import React, { Key, useState } from 'react';

type RolePickModalProps = {
  onRoleSelect?: Function1<Key[], void>;
  roles?: RoleData[];
} & ModalProps;

const filterOption = (inputValue: string, option) => option.title.indexOf(inputValue) > -1;
export const useRolePickModal = (): [React.FC<RolePickModalProps>, Function1<Key[], void>] => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [roleIds, setRoleIds] = useState<Key[]>();

  const RolePickModal = useMemoizedFn(({ children, onRoleSelect, roles, ...params }) => {
    const dataSource = useCreation(
      () =>
        roles?.map((v: RoleData) => ({
          key: v.id,
          title: v.roleName,
          description: v.remark,
        })),
      [roles],
    );

    return (
      <Modal
        title={dict('SELECT_NAME', { name: dict('ROLE') })} //选择角色
        open={isModalOpen}
        onCancel={hideModal}
        destroyOnClose
        onOk={() => {
          onRoleSelect?.(roleIds);
          hideModal();
        }}
        width={600}
        {...params}
      >
        <Flex justify="center">
          <Transfer
            dataSource={dataSource}
            showSearch
            filterOption={filterOption}
            listStyle={{
              width: 250,
              height: 300,
            }}
            targetKeys={roleIds as string[]}
            render={(item) => item.title}
            onChange={(nextTargetKeys: string[]) => setRoleIds(nextTargetKeys)}
          />
        </Flex>
      </Modal>
    );
  });

  const handleRecord = useMemoizedFn((roleIds: Key[]) => {
    setRoleIds(roleIds);
    showModal();
  });

  return [RolePickModal, handleRecord];
};
