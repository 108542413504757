import Text, { ArrLabelText } from '@/components/Form/Field/Text';
import { Blank, FormCard } from '@/components/PageCard';
import { defalutFormInputStyle, defaultFormItemConfig } from '@/constants/const';
import { FormOpType } from '@/constants/enum';
import { REGEX } from '@/constants/regex';
import { useAppContext } from '@/contexts/AppContext';
import { checkKeywordCategoryName } from '@/services/keyword_category';
import { findDuplicates } from '@/utils/arr';
import { Validator } from '@/utils/validator';
import { Alert, App, Button, Checkbox, Form, Input, Radio, Space, Switch } from '@antd';
import { useBoolean } from 'ahooks';
import { get, isEmpty } from 'lodash';
import React, { Key, useEffect } from 'react';
import { KeywordStatus, KeywordUsedTypes, removeTemporaryFields, validateKeywrod } from '../../const.var';
import { DynamicRules } from './DynamicRules';
import { KeywordTextArea } from './KeywordTextArea';
import { dict } from '@/hooks/useChangeLocale';

type FormFieldsType = {
  initValue?: any;
  type: FormOpType;
  onFormSubmit?: (values: any) => void;
  id?: Key;
  back?: VoidFunction;
};
const FormFields: React.FC<FormFieldsType> = ({ initValue, type, onFormSubmit, back, id }) => {
  const { message } = App.useApp();
  const { user } = useAppContext();
  const [form] = Form.useForm();
  const [formUnchanged, { setFalse: setFormChanged }] = useBoolean(true);
  const [includedRulesForm] = Form.useForm();
  const [excludedRulesForm] = Form.useForm();
  const enableIncludedDynamic = Form.useWatch('enableIncludedDynamic', form);
  const enableExcludedDynamic = Form.useWatch('enableExcludedDynamic', form);
  const keywordType = Form.useWatch('type', form);

  const isView = type == FormOpType.VIEW;

  const submit = async () => {
    try {
      const values = await form.validateFields();
      const { includedKeywords = [], includedRules = [], excludedKeywords = [], excludedRules = [] } = values;
      if (Validator.isNilEmpty(includedKeywords) && Validator.isNilEmpty(includedRules)) {
        return message.error(dict('CONFIG_KEYWORD_RULE_TIP'));
      }

      const repeatKeywords = findDuplicates(
        [...includedKeywords, ...excludedKeywords].concat([...includedRules, ...excludedRules].map((v) => v.name)),
      );
      if (!isEmpty(repeatKeywords)) {
        return message.error(dict('CONFIG_KEYWORD_RULE_REPEATION_TIP', { keywrod: repeatKeywords.join(',') }));
      }

      //移除规则里的临时ID
      values.includedRules = values.includedRules?.map(removeTemporaryFields);
      values.excludedRules = values.excludedRules?.map(removeTemporaryFields);

      onFormSubmit?.(values);
    } catch (error: any) {
      if (error?.errorFields) {
        form.scrollToField(get(error, 'errorFields[0].name[0]', ''));
      } else {
        message.error(get(error, 'status.message', dict('OPERATION_FAILURE')));
      }
    }
  };

  useEffect(() => form.setFieldsValue(initValue), [initValue]);

  return (
    <>
      <Alert message={dict('KEYWORD_CHANGED_TIP')} style={{ marginBottom: 10 }} />
      <Form
        {...defaultFormItemConfig}
        form={form}
        validateTrigger={['onSubmit', 'onChange', 'onBlur']}
        onValuesChange={setFormChanged}
      >
        <FormCard title={dict('BASIC_INFO')}>
          <Form.Item
            label={dict('CLASSIFICATION_NAME')}
            name="name"
            required={!isView}
            rules={[
              {
                max: 50,
              },
              {
                validateTrigger: 'onBlur',
                async validator(_, name) {
                  if (!name) return Promise.reject(dict('PLEASE_ENTER_CATEGROY_NAME'));
                  if (!REGEX.PLAIN_TEXT.test(name)) return Promise.reject(dict('CATEGROY_NAME_VERIFICATION_TIP'));
                  try {
                    const exist = await checkKeywordCategoryName({ id, name, bid: user?.bid, type: keywordType });
                    if (exist) return Promise.reject(dict('CATEGROY_EXISTED'));
                  } catch (error) {
                    return Promise.reject(get(error, 'status.message', dict('VALIDATION_EXCEIPTION')));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            {isView ? <Text /> : <Input placeholder={dict('PLEASE_TYPE')} style={defalutFormInputStyle} />}
          </Form.Item>

          <Form.Item label={dict('AFFECT')} name="type" required={!isView} rules={[{ required: !isView }]}>
            {isView ? <ArrLabelText list={KeywordUsedTypes} /> : <Checkbox.Group options={KeywordUsedTypes} />}
          </Form.Item>

          <Form.Item label={dict('STATUS')} name="disabled">
            {isView ? <ArrLabelText list={KeywordStatus} /> : <Radio.Group options={KeywordStatus} />}
          </Form.Item>

          <Form.Item label={dict('REMARK')} name="remark" rules={[{ max: 255 }]}>
            {isView ? <Text /> : <Input.TextArea maxLength={255} showCount style={defalutFormInputStyle} />}
          </Form.Item>
        </FormCard>
        <Blank />
        <FormCard title={dict('INCLUDE_KEYWORDS')} subTitle={dict('INCLUDE_KEYWORDS_CONFIG_SUB_TIP')}>
          <Form.Item
            label={dict('INCLUDE_KEYWORDS')}
            name="includedKeywords"
            extra={isView ? undefined : dict('KEYWROD_VERIFICATION_RULE_TIP')}
            rules={[validateKeywrod]}
          >
            <KeywordTextArea
              readOnly={isView}
              style={defalutFormInputStyle}
              placeholder={dict('KEYWROD_VERIFICATION_RULE_PLACEHOLDER')}
            />
          </Form.Item>

          <Form.Item label={dict('DYNAMIC_RULE')} name="enableIncludedDynamic" valuePropName="checked">
            <Switch disabled={isView} />
          </Form.Item>

          {enableIncludedDynamic && (
            <Form.Item
              label={dict('RULE_CONFIG')}
              name="includedRules"
              required
              rules={[
                {
                  validateTrigger: 'onSubmit',
                  async validator(rule, value) {
                    if (isEmpty(value)) return Promise.reject(dict('CONFIG_RULE_REQUIRED_TIP'));
                    try {
                      await includedRulesForm.validateFields();
                    } catch (error) {
                      return Promise.reject(dict('CONFIG_RULE_ITEM_REQUIRED'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <DynamicRules readonly={isView} form={includedRulesForm} />
            </Form.Item>
          )}
        </FormCard>
        <Blank />
        <FormCard title={dict('EXCLUDED_KEYWORDS')} subTitle={dict('EXCLUDE_KEYWORDS_CONFIG_SUB_TIP')}>
          <Form.Item
            label={dict('EXCLUDED_KEYWORDS')}
            name="excludedKeywords"
            extra={isView ? undefined : dict('KEYWROD_VERIFICATION_RULE_TIP')}
            rules={[validateKeywrod]}
          >
            <KeywordTextArea
              style={defalutFormInputStyle}
              readOnly={isView}
              placeholder={dict('KEYWROD_VERIFICATION_RULE_PLACEHOLDER')}
            />
          </Form.Item>

          <Form.Item label={dict('DYNAMIC_RULE')} name="enableExcludedDynamic" valuePropName="checked">
            <Switch disabled={isView} />
          </Form.Item>

          {enableExcludedDynamic && (
            <Form.Item
              label={dict('RULE_CONFIG')}
              name="excludedRules"
              required
              rules={[
                {
                  validateTrigger: 'onSubmit',
                  async validator(rule, value) {
                    try {
                      if (isEmpty(value)) return Promise.reject(dict('CONFIG_RULE_REQUIRED_TIP'));
                      await excludedRulesForm.validateFields();
                    } catch (error) {
                      return Promise.reject(dict('CONFIG_RULE_ITEM_REQUIRED'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <DynamicRules readonly={isView} form={excludedRulesForm} />
            </Form.Item>
          )}
        </FormCard>
        {type != FormOpType.VIEW && (
          <>
            <Blank />
            <FormCard>
              <Space style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type="primary" disabled={formUnchanged} onClick={submit}>
                  {dict('ACTION_SUBMIT')}
                </Button>
                <Button onClick={back}>{dict('ACTION_CANCEL')}</Button>
              </Space>
            </FormCard>
          </>
        )}
      </Form>
    </>
  );
};

export default FormFields;
